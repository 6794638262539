import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Virgo.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Virgo Relationship
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/virgo"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Virgo</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Virgo Relationship</h4>
              
              
             
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Virgo Relationship </h1>
                <h2 className="text-sm md:text-base ml-4">July 23 - August 22</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Virgo Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Virgo Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Virgo Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-love");
                  }}>
                  <h5 className="font-semibold text-black">Virgo Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-nature");
                  }}>
                  <h5 className="font-semibold text-black">Virgo Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-man");
                  }}>
                  <h5 className="font-semibold text-black">Virgo Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Virgo Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-traits");
                  }}>
                  <h5 className="font-semibold text-black">Virgo Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-facts");
                  }}>
                  <h5 className="font-semibold text-black">Virgo Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
                  If you have a Virgo as your partner, they will be incredibly
                  soft in nature, kind and forgiving, and caring towards you. By
                  nature, they are conservative, and Virgos are often slow in
                  expressing their love emotions. They can't easily fall in
                  love, but they are entirely devoted to their partner if they
                  fail.
                  <br />
                  <br />
                  Virgo in love appreciates their partner's choice, and they
                  give their partner complete freedom to choose their career,
                  never forcing them to do anything; they are very supportive
                  when they are in love. Self-restraint is essential in Virgo
                  relationships. A Virgo in love will determine and take a
                  little more time to find their perfect partner. But once they
                  see that person in their life, they will need ve very
                  trustworthy, loyal, honest, and passionate towards you.
                  <br />
                  <br />
                  Virgos don't waste their time in a short-term relationship,
                  and they are a firm believer in lifelong relationships, and
                  betrayal can be highly devastating. One can predict serenity
                  in connection with Virgo as they have a sensible and
                  straightforward approach to life. When you love someone, your
                  dream extends to your loved one. You constantly nurture the
                  plant of your relationships by helping each other and making
                  your partner more organized and victorious. This makes their
                  relationship way better than others because they give their
                  100% to their relationship and supports each other in every
                  adverse circumstance.
                  <br />
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">
                    Virgo as a lover
                  </p>
                  <br/>
                  Virgos are not one for offering excellent displays of
                  affection. You will successfully rob your partner's heart by
                  revealing your emotions of love and sentiments from your
                  efforts instead of words. You have the gift of wooing your
                  route into your companion's heart. You elongate time to fall
                  in love. You are not easily trapt in anyone's cheesy
                  proposals. By nature, you're an introvert when sharing your
                  feelings or sentiments. That's why you take a longer time to
                  confess or convey your feelings.
                  <br />
                  <br />
                  You can't easily trust anyone you don't know or might be known
                  just for some time. Love is all about taking time and growing
                  emotions constantly, and after that, when you feel sure about
                  your feelings, you only confront that person. You take time to
                  open up in relation. But when you fall in love, it will be
                  intense, and you will be exceptionally kind and loving,
                  caring, and get devoted partners. Although you are not
                  authentic romantics, you take your emotions seriously and
                  envision the same from your partners. You have some mysterious
                  or vague aura that others find appealing and want to realize.
                  You will infrequently allow your core to rule your heads even
                  though you are practical-minded.
                  <br />
                  <br />
                  You expect the same as the person should concern about, like
                  understanding, empathy, honesty, kindness, and respect in
                  romantic relationships. However, you are intensely committed
                  to family life and loved ones. You are ready to do everything
                  to keep a connection going swiftly. At the same time, you
                  desire loyalty and honesty from your beloved. You seem to be
                  very stoic and insensitive from the outside. But in fact, you
                  do have a diplomatic and passionate side. However, you lead
                  this side only to your loved ones.
                  <br />
                  <br />
                  Get an understanding of your love life with free love
                  horoscope information.
                  <br />
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">
                    Virgo as a colleague
                  </p>
                  <br/>
                  Virgos are understood to be perfectionists in the workplace.
                  You are careful, engaged, and determined in your job. You have
                  a sharp eye for every minute detail. You always want to
                  deliver quality work, and your system to perform is exact.
                  <br />
                  <br />
                  Therefore, you treat all assignments and tasks with the utmost
                  care. You will demand on bringing petty things done your way.
                  You rarely concentrate on the big picture. However, you
                  recognize every error made by your teammates. You have high
                  standards and desire the same in return. You are not the ones
                  who turn in unfinished or shoddy work.
                  <br />
                  <br />
                  Virgos are often crucial to their work. However, you can be
                  irritatingly prompt to point out others' flaws. You find the
                  problem in perceiving mediocrity. Hence, you will constantly
                  move your teammates and yourself to produce quality output.
                  <br />
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">
                    Virgo as a friend
                  </p>
                  <br/>
                  Virgos are easygoing and amiable towards everyone. Your
                  buddies will permanently spell out what's there in their
                  minds. They even communicate their concerns with you. You may
                  have some barriers around you. The wall is created mainly to
                  rescue you, your close ones, and the people in your in-circle.
                  You are the one who takes friendship very incredibly and won't
                  befriend someone you believe is not trustworthy and tolerable.
                  You are comfortable sharing your view with your friends. You
                  will also inform them when they are not accomplishing their
                  best.
                  <br />
                  <br />
                  Virgo's directions greatness from their friends. Your quick
                  mind is swift to indicate if you don't believe somebody is not
                  living up to your ideals. You are selective and choose your
                  companions very carefully. You are only curious about friends
                  who you can rely on. You maintain a kind and affectionate
                  attitude. Hence, your buddies can continuously count on you
                  when the chips are down.
                  <br />
                  <br />
                  Every companionship is unique, after all. But according to
                  astrology, some sun signs play adequately with others. So who
                  are your most profitable and worst friend matches?
                  <br />
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">
                    Virgo as a boss
                  </p>
                  <br/>
                  A Virgo boss has his look at the minute details and details in
                  the workplace. You are interested in everything moving on in
                  the office and around you. You are constantly in touch with
                  your workers.
                  <br />
                  <br />
                  Whenever you call your team discussions, you desire your
                  employees to have complete information on everything they have
                  done. You will also expect them to convey what they are doing
                  next. Thus, you desire them to have a comprehensive plan from
                  beginning to end. You can be a delicate, kind-hearted, gentle,
                  and wise adviser. You are acceptable at exploring and
                  determining what needs to be done. Even you give
                  straightforward instructions to your workers as to how it has
                  to be done.
                  <br />
                  <br />
                  On another side, you can be a finicky micromanager. You can
                  unsympathetically criticize and consider every move of your
                  employees. However, you quickly point out errors and never
                  seem pleased easily. In complement to this, you may ask for
                  duplicated modifications to what your workers are working on.
                  Even you lend a helping hand to them to get the career done
                  correctly. To comprehend the pros and cons of your connection
                  with your boss: <br />
                  <br />
                  Get a fully personalized and handwritten compatibility report
                  for a boss and you.
                  <br />
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">
                    Virgo Fathers
                  </p>
                  <br/>
                  You are a patient and caring father who keeps your child happy
                  and molds him/her to achieve a respectful position. You are
                  very trustworthy and devoted to your family. Though shy, you
                  want to be prepared for everything your child might throw at
                  you. It can be both metaphorical and occasionally literal.
                  Also, will read parenthood books and videos before becoming a
                  father. You will find a perfect way to encourage them as well.
                  <br />
                  <br />
                  You will perform very hard to make sure that you fulfill all
                  your family's wishes. Also, you work challenging to ensure
                  that your kid will not face any economic issues in the future.
                  You even show sufficient involvement at home to maintain
                  things in order. However, you are not scared to help your
                  youth whenever they need it. You have a heightened level of
                  toleration. Hence, you do not get worried easily during
                  difficult situations. You are usually interested in the
                  creation and get annoyed by an unnecessary exhibit of
                  emotions.
                  <br />
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">
                    Virgo Mothers
                  </p>
                  <br/>
                  Virgo mothers have excellent administrative skills. These
                  permits allow you to stop your kid's plans and see to his/her
                  needs. Still, you can prepare some family time and some alone
                  time. You systematize your home, and it is continuously
                  immaculate and spotlessly hygienic. You even consider keeping
                  wholesome circumstances. You are likely to drop everything to
                  be with your family if need be. So your kids know that they
                  can count on you for any sort of support. Whenever your child
                  needs unique help or educational support, you are continuously
                  available to support him/her.
                  <br />
                  <br />
                  You are sensible, intelligent, focused, and possess a high
                  patience level. However, you are particularly fussy about
                  promptness. Challenges will not bother your state of mind as
                  you are adept at discovering answers to those challenges. You
                  are pretty strict about teaching the correct manners and
                  specialization in them. You pay particular attention to the
                  healthiness of your kid. Also, you encourage him to take up
                  challenges in life and experience extracurricular activities.
                  However, you refuse all the wrong demands of the youth without
                  hesitation.
                  <br />
                  <br />
                  Every parent presents their children differently. Know your
                  parenthood style according to the zodiac sign
                  <br />
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">
                    Virgo as a Child
                  </p>
                  <br/>
                  Virgo child values taking command and giving an order. You
                  keep an excellent standard sense and deliver keen awareness to
                  every detail. You will consistently have pleasure in pushing
                  discoveries and leading them off to people who would otherwise
                  have walked right by. You want to organize your toys or save
                  your purse cash-on-hand to buy some longed-for item. Rather
                  than being sudden, you examine the circumstances and make a
                  conclusive decision. You have increased measures and are
                  inherently cautious.
                  <br />
                  <br />
                  At the same time, you can be selective and timid. However, you
                  choose to stand back and believe in a situation rather than
                  throw yourself into it. You are a genuine helper who genuinely
                  likes to help out and does the right things for your home and
                  the individuals around you. You seek ideal and are likely to
                  be self-critical and extremely modest. Although you have
                  innate brilliance, you are particularly susceptible and
                  inclined to feel uncertain and inferior at times.
                  <br />
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">
                    Virgo as a Husband
                  </p>
                  <br/>
                  Best marriage match: Taurus, Gemini, Cancer, Sagittarius,
                  Capricorn woman. You are cheerful to play a supportive role in
                  the relationship. You give reference to everyone. You will
                  love your in-laws and comprehensive family exactly as you love
                  yours. Although you are incredibly hard-working, you do not
                  give much significance to quality and recognition. Your wife
                  will depend upon you to handle your mutual finances and the
                  significant conditions of the family.
                  <br />
                  <br />
                  Virgo man will not show his emotions through flamboyant
                  gestures or gooey emotions. However, you do not think about
                  the grand dramatic gestures. But you choose to confess your
                  feelings through realistic actions and continue supporting
                  your partner. You do not mind cleaning, cooking, or doing
                  other household tasks. You will consistently keep your wife.
                  Also, you will adore seeing her develop as an individual and
                  have her identity. You are a perfectionist and the most
                  attentive man in the world. Hence, you tend to evolve
                  positively critically and may nag your spouse. You love
                  independence and will have no misgivings in extending it
                  equally to your partner.
                  <br />
                  <br />
                  Know the possibilities of your marriage and concerns related
                  to the union in 2024 with the help of the Virgo marriage,
                  family, and child horoscope for 2024
                  <br />
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">
                    Virgo as a Wife
                  </p>
                  <br/>       
                  <p className="font-bold inline-block text-orange-500">Best marriage match:</p>Taurus, Gemini, Cancer, Sagittarius,
                  Capricorn man. The enchanted beauty of a Virgo woman will
                  please many men.
                  <br />
                  <br />
                  A Virgo woman is a perfectionist, meticulous, hypercritical,
                  heartless, and conservative identity that can upset her
                  people. However, you are too demanding, and your exotic taste
                  is entirely different from other women. In addition to this,
                  you maintain a balanced feeling. Hence, you are neither too
                  nervous nor too open. You design yourself as the most
                  organized someone. You are still concerned regarding
                  nutritional habits and orderliness in life.
                  <br />
                  <br />
                  Besides perceiving attractiveness, you also remember a
                  brilliant mind and fair obedience power. You are dedicated,
                  devoted, and committed to your husband. You acknowledge
                  high-traditional and family values. However, you simply
                  deliver your sympathy. Above this, you can keep private
                  feelings under friendly control.
                  <br />
                  <br />
                  You do not lose your hopes quickly. To perform expected goals,
                  you manage resources. Also, you are severe about your
                  day-to-day routine. You systematically implement your plans
                  and strategies to achieve your desired goals. You choose to be
                  quiet and stay isolated for some time when concerned or
                  anxious. You believe in solutions in your only time.
                  <br />
                  <br />
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
